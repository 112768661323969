footer {
  position: relative;
  z-index: 1;
  .sol-bottom {
    padding: 40px 0;
    background-color: @footer-bg;
    & .sv-grid-main .sv-row {
      & > .sv-column-4 {
        padding-right: 30px;
      }
    }
    .sol-footer-contact,
    .sol-footer-links {
      &-item {
        display: block;
        position: relative;
        margin-bottom: 11px;
        padding-left: 30px;
        text-decoration: none;
        color: @footer-text;
        &--icon-links {
          & a::before {
            .icon(@fa-var-angle-right, 14px, block);
            width: 20px;
            height: 20px;
            margin-right: 10px;
            padding-left: 0;
            background-color: shade(@footer-bg,25%);
            //border: 1px solid rgba(255, 255, 255, 0.7);
            border-radius: 50%;
            opacity: 1;
            line-height: 20px;
            text-align: center;
            font-size: 16px;
            font-weight: 400;
            position: absolute;
            left: 0;
            top: 1px;
            box-sizing: border-box;
          }
        }
        & a,
        & p {
          display: block;
          text-decoration: none;
          color: @footer-text;
          font-size: 14px;
          line-height: 20px;
          font-weight: 300;
          margin: 0;
        }
        .transition(opacity);
        & i.fal:first-child {
          position: absolute;
          left: 0;
          top: 1px;
          font-size: 17px;
        }
      }
      & a:hover {
        opacity: 0.7;
        text-decoration: underline;
      }
    }
    .sol-social-media {
      .sol-clearfix;
      .sol-social-media-item > a {
        width: 40px;
        height: 40px;
        margin-right: 7px;
        float: left;
        border: 2px solid @footer-text;
        border-radius: 50%;
        color: @footer-text;
        font-size: 21px;
        text-align: center;
        line-height: 34px;
        .transition(opacity);
        &:hover {
          opacity: 0.5;
        }
      }
    }
    & .font-footer-header {
      margin-bottom: 20px;
      margin-top: 0;
      padding: 10px 30px;
      border: shade(@footer-bg,20%);
      font-size: 18px;
      font-family: @main-font;
      color: @button-text;
      line-height: 22px;
      font-weight: 400;
      text-decoration: none;
      background: shade(@footer-bg,15%);
      border-radius: @border-radius-large;
      width: 100%;
      text-align: left;
      &:hover {
        background: shade(@footer-bg,30%);
      }
      &.sol-footer-important-link {
        background: @white;
        color: @black;
        &:hover {
          background: @bg-gray;
        }
      }
      i {
        margin-right: 5px;
      }
    }
  }


  

  a {
    color: @footer-text;
    text-decoration: none;
  }
  .sol-tool-contact {
    cursor: pointer;
  }

  p.font-footer-text {
    margin-bottom: 11px;
    font-size: 14px;
    font-family: @main-font;
    color: @footer-text;
    line-height: 22px;
    font-weight: 700;
    a:hover {
      color: fadeout(@footer-text, 30%);
      text-decoration: underline;
    }
  }
}
