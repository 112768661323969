.clearfix() {
  &:after,
  &:before {
    content: "";
    display: table;
  }
  &:after {
    clear: both;
  }
}
.no-list-style() {
  list-style: none;
  padding: 0;
  margin: 0;
}
.placeholder(@rules) {
  &::-webkit-input-placeholder {
    @rules();
  }
  &:-moz-placeholder {
    @rules();
  }
  &::-moz-placeholder {
    @rules();
  }
  &:-ms-input-placeholder {
    @rules();
  }
}
.no-outline-focus() {
  &:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-appearance: none;
    outline: none;
  }
}
.outline-focus() {
  &:focus,
  [data-whatinput="keyboard"] &:focus {
    outline: 2px solid @focus-border;
    outline-offset: -2px;
    z-index: 10;
  }
  [data-whatinput="initial"] &:focus,
  [data-whatinput="mouse"] &:focus,
  [data-whatinput="touch"] &:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-appearance: none;
    outline: none;
    z-index: inherit;
  }
}
.no-appearance() {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  border: none;
}
.icon(@icon; @size:inherit; @display:inline-block; @position: none) {
  .fa;
  display: @display;
  font-size: @size;
  .position(@position);
  .position(@position) when (@position =before) {
    &::before {
      content: @icon;
    }
  }
  .position(@position) when (@position =after) {
    &::after {
      content: @icon;
    }
  }
  .position(@position) when (@position =none) {
    content: @icon;
  }
}
.transition( @property; @duration:200ms; @style: ease) {
  -webkit-transition-property: @property;
  transition-property: @property;
  -webkit-transition-duration: @duration;
  transition-duration: @duration;
  -webkit-transition-timing-function: @style;
  transition-timing-function: @style;
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.focus-outline() {
  &:focus,
  [data-whatinput="keyboard"] &:focus {
    outline: 2px solid @focus-border;
    z-index: 10;
  }
  [data-whatinput="initial"] &:focus,
  [data-whatinput="mouse"] &:focus,
  [data-whatinput="touch"] &:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-appearance: none;
    outline: none;
  }
}
.rotate(@deg) {
  -webkit-transform: rotate(@deg);
  -ms-transform: rotate(@deg);
  transform: rotate(@deg);
}
.link-style(@bottom: 1px, @sides: 0, @bg-hover: rgba(110, 205, 233, 0.6), @border-color: rgba(110, 205, 233, 0.6)) {
  position: relative;
  z-index: 1;
  text-decoration: none;
  background-size: 200% 200%;
  background-image:
    linear-gradient(to top, @bg-hover 50%, transparent 50%);
  -webkit-transition: background-position 300ms, color 300ms ease, border-color 300ms ease;
  -moz-transition: background-position 300ms, color 300ms ease, border-color 300ms ease;
  -ms-transition: background-position 300ms, color 300ms ease, border-color 300ms ease;
  -o-transition: background-position 300ms, color 300ms ease, border-color 300ms ease;
  transition: background-position 300ms, color 300ms ease, border-color 300ms ease;
  padding-left: 1px;
  padding-right: 1px;
  border-bottom: 1px solid @bg-hover;
  &:hover {
    background-image:
      linear-gradient(to top, @bg-hover 51%, transparent 50%);
    background-position: 0 100%;
    -webkit-transition: background-position 300ms, color 300ms ease, border-color 300ms ease;
    -moz-transition: background-position 300ms, color 300ms ease, border-color 300ms ease;
    -ms-transition: background-position 300ms, color 300ms ease, border-color 300ms ease;
    -o-transition: background-position 300ms, color 300ms ease, border-color 300ms ease;
    transition: background-position 300ms, color 300ms ease, border-color 300ms ease;
  }
}
