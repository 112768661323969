.sol-collapsible {
    //background: @white;
    //border: 1px solid @tbody-border;
    border-radius: @border-radius-normal;
    margin-bottom: 15px;
    background: @tbody-odd;
    &__header {
        .no-appearance;
        .focus-outline;
        position: relative;
        display: block;
        width: 100%;
        padding: 16px 65px 16px 20px;
        background-color: @tbody-odd;
        border-radius: @border-radius-normal;
        color: @black;
        font-family: @main-font;
        text-align: left;
        text-decoration: none;
        line-height: 1.1;
        font-weight: 500;
        font-size: 22px;
        margin: 0;
        cursor: pointer;
        &__icon {
            display: block;
            width: 56px;
            height: 100%;
            position: absolute;
            right: 0;
            top: 0;
            background: @collapsible-bg;
            border-top-right-radius: @border-radius-normal;
            border-bottom-right-radius: @border-radius-normal;
            &::after,
            &::before {
                content: "";
                display: block;
                background-color: @white;
                position: absolute;
                top: 50%;
                left: 50%;
                width: 20px;
                height: 4px;
                margin-left: -10px;
                margin-top: -1px;
                .transition(all);
            }
            &::after {
                .rotate(90deg);
            }
        }
        &--active {
            border-bottom-left-radius: 0;
            background-color: @collapsible-bg;
            color: @white;
            font-weight: 600;
            & .sol-collapsible__header__icon {
                border-bottom-right-radius: 0;
                &::after {
                    .rotate(0deg);
                }
            }
        }
    }
    & .sol-collapsible__content,
    & .sv-decoration-content {
        display: none; //background-color: @box-decoration-bg;
        //box-shadow: 0 0 5px 0 rgba(0, 0, 0, .1);
        //border-radius: 3px;
        padding: 20px; //margin-left: 6px;
        //margin-right: 6px;
        &>*:last-child {
            margin-bottom: 0;
        }
        & p:last-of-type {
            margin: 0;
        }
        .sv-editing-mode & {
            display: block;
        }
    }
}

.sol-collapsible.sol-collapsible-alt {
    background: @white;
    border: 1px solid fadeout(@gray-1, 50%);
    & .sol-collapsible__header {
        font-size: 16px;
        background: @white; //border-radius: @border-radius-small;
        padding: 14px 50px 13px 30px;
        &__icon {
            height: 46px; //width: 50px;
            margin-top: -23px;
            background: transparent; //border-top-right-radius: @border-radius-small;

            &::after,
            &::before {
                background: @black;
            }
        }
        &--active {
            border-bottom-right-radius: 0;
            background-color: @collapsible-bg;
            color: @white;
            & .sol-collapsible__header__icon {
                border-bottom-right-radius: 0;
                &::after,
                &::before {
                    background: @white;
                }
                &::after {
                    .rotate(0deg);
                }
            }
        }
    }
}

.sv-edit-mode .sol-collapsible__header {
    .sol-collapsible__header__icon::after {
        .rotate(0deg);
    }
    &.sol-collapsible__header--active .sol-collapsible__header__icon::after {
        .rotate(90deg);
    }
}
