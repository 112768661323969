.sol-breadcrumbs-wrapper {
  .sol-hide-sm;
  background-color: @breadcrumbs-bg;
  //background: @white;
  //border-top: 1px solid #ebebeb;
  padding-top: 20px;
  padding-bottom: 20px;
  .sol-breadcrumbs {
    .sol-clearfix;
    & li {
      float: left;
      & > a,
      & > span {
        font-size: 14px;
      }
      & > a {
        text-decoration: none;
        color: @breadcrumbs-text;
        &:hover {
          color: @breadcrumbs-text-hover;
          text-decoration: underline;
        }
      }
      & > span {
        color: @breadcrumbs-text-active;
        &.sol-divider {
          margin-right: 3px;
          margin-left: 5px;
        }
      }
    }
  }
}