header {
  position: fixed;
  width: 100%;
  z-index: 50;
  //background-color: @header-top-bg;

  .sol-top {
    padding: 15px 45px;
    background-color: @header-top-bg;
    position: relative;
    width: 100%;
    height: 80px;
    border-bottom: 1px solid fadeout(#ccc, 50%);

    @media @md {
      padding-left: 7px;
      padding-right: 7px;
      padding-top: 13px;
      padding-bottom: 8px;
    }

    .sol-top-text-container {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      text-align: center;
      padding-top: 24px;

      @media @sm {
        right: 25px;
        text-align: right;
      }

      & > .sol-top-text {
        text-decoration: none;
        font-size: 24px;
        font-weight: 700;
        color: @form-red-1;

        &:hover {
          color: @form-red-2;
          text-decoration: underline;
        }
      }
    }
  }

  & + div + div + div.sv-layout {
    padding-top: 80px;
  }

  .sol-bottom {
    background-color: @header-menu-bg;
    text-align: center;

    @media @md {
      display: none;
    }
  }
}

.sol-to-content {
  position: absolute;
  margin-left: 100%;
  right: 100%;
  top: -3px;
  margin-right: 20px;
  width: 0;
  height: 0;
  white-space: nowrap;
  overflow: hidden;
  line-height: 30px;
  text-align: center;
  font-weight: 600;
  background-color: @color-1;
  font-size: 14px;
  color: @white;

  &:focus {
    width: auto;
    height: auto;

    @media @sm {
      position: static;
      width: 100%;
      margin-bottom: 10px;
    }
  }
}
