.sol-event-item {
    &-heading {
        font-size: 20px;
        line-height: 24px;
        font-weight: 500;
    }
    &-date {
        border-radius: @border-radius-normal;
    }
    &-info {
        margin-bottom: 3px;
        margin-bottom: 2px;
        font-weight: 400;
    }
}
