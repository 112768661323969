.sv-layout:not(.sv-editable-layout) > .sol-modal-decoration {
  & > .sv-layout > .sv-decoration-content {
    display: none;
  }
}

.sol-modal {
  &-mask {
    position: fixed !important;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: table;
    -webkit-transition: opacity 0.3s ease;
    transition: opacity 0.3s ease;
  }
  &-container {
    max-width: 900px;
    max-height: ~'calc(100vh - 250px)';
    margin-top: 100px;
    margin-right: auto;
    margin-left: auto;
    background-color: @white;
    padding: 35px 40px;
    position: relative;
    overflow: auto;
    & .sv-grid-main.sv-fluid-grid {
      padding-left: 0;
      padding-right: 0;
    }
    //Behövs för att få modalen att se snygg ut.-JN//
    & h1.font-heading-1 {
      margin-bottom: 24px;
    }


    .sol-related {
      &-item {
        margin-bottom: 0;
        &-name {
          line-height: 1.5em; //Samma lineheight som i "typography.less
        }
      }
    }    

    .font-heading-2 {
      margin-top: 0;
      margin-bottom: 20px;
    }

    @media @md {
      max-width: 700px;
    }
    @media @sm {
      max-width: 800px;
      max-height: 100vh;
      margin-top: 0;
      height: 100%;
      padding: 60px 20px 0;
    }
  }
  &-close {
    .no-appearance;
    position: absolute;
    top: 0;
    right: 0;
    padding: 0;
    width: 55px;
    height: 55px;
    background: @primary-color;
    cursor: pointer;
    i.fal {
      font-size: 30px;
      color: @white;
    }
    &:hover i.fal {
      color: fadeout(@white, 20%);
    }
  }
}

.sol-modal-decoration {
  button.font-btn {
    .button;
    margin-bottom: 20px;
  }
}
