.sol-startpage-entrances,
.sol-landing-page__listing {
    background-color: @startpage-entrance;
    background-image: 
    url(/webdav/files/System/resources/graphics/Mora_kurbits-1l.svg),
    url(/webdav/files/System/resources/graphics/Mora_kurbits-1r.svg);
  background-position:
    left 50%,
    right 50%;
  background-repeat:
    no-repeat;
    background-size: 250px 100%;
    @media @lg {
      background-image: url(/webdav/files/System/resources/graphics/Mora_kurbits-1l.svg);
      background-size: 290px 100%;
   
    }
    @media @md {
      background-size: 70%  100%;
      background-image: none;
    }
}
