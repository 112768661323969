.sol-popular-pages {
  .sol-clearfix;
  background-color: @top-content-popular-bg;
  padding: 10px 15px 5px;
  & > a,
  & > div {
    color: @top-content-popular-text;
    float: left;
    margin-bottom: 5px;
    @media @sm {
      font-size: 13px;
    }
  }
  & > div {
    display: inline-block;
    margin-right: 10px;
    font-weight: 600;
  }
  & > a {
    opacity: 1;
    margin-right: 10px;
    text-decoration: none;
    .transition(opacity);
    &:hover {
      opacity: 0.7;
    }
    @media only screen and (max-width: 767px) and (min-width: 350px) {
      width: 50%;
      margin-right: 0;
    }

    & i.fal {
      width: 18px;
      height: 18px;
      margin-right: 2px;
      //border: 1px solid @top-content-popular-text;
      //border-radius: 50%;
      text-align: center;
      padding-left: 1px;
      padding-top: 1px;
    }
  }
  &-first {
    @media @sm {
    width: 100%;
  }
  }
}
