.sol-breadcrumbs-wrapper {
  .sol-hide-sm;

  .sol-breadcrumbs {
    .sol-clearfix;

    & li {
      float: left;

      & > a,
      & > span {
        font-size: 16px;
        font-weight: 400;
      }

      & > a {
        text-decoration: underline;
        color: @breadcrumbs-text;

        &:hover {
          color: @breadcrumbs-text-hover;
          text-decoration: underline;
        }
      }
    }
  }
}
