.sol-article-item {
  text-decoration: none;
  padding-top: 20px;
  padding-bottom: 40px;
  border-bottom: 1px solid #ebebeb;

  &__img {
    width: 133px;
    height: 114px;
    object-fit: cover;
    object-position: 50%;
    font-family: 'object-fit: cover; object-position: 50%;';
  }

  &__content {
    display: flex;
    &--left {
      flex: 0 0 133px;
      & ~ div {
        width: ~'calc(100% - 133px)';
      }
    }
    &--right {
      padding-right: 30px;
      font-family: @heading-font;
    }
  }

  &__date {
    font-size: 11px;
    line-height: 18px;
    margin-bottom: 10px;
    display: flex;
    div {margin-right: 1em}
    & i.fa,
    & i.far,
    & i.fal,
    & i.fab,
    & i.fas {
      font-size: 18px;
      margin-right: 5px;
    }
  }

  &__heading {
    font-size: 23px;
    font-weight: normal;
    margin-top: 0;
    margin-bottom: 5px;
    & a {
      text-decoration: none;
    }
  }

  &__desc {
    font-size: 15px;
    display: block;
    margin-bottom: 0;
  }

  &--has-img {
    .sol-article-item__content {
      &--right {
        padding-left: 18px;
        flex-direction: column;
        justify-content: center;
      }
    }
  }
}
