.sol-startpage-top-images {
  //position: fixed;
  z-index: -1;
  width: 100%;
  &-item {
    height: 385px;
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    @media @md {
      height: 150px;
    }
    @media @sm {
      display: flex !important;
    }

  }
  & .slick-dots {
    list-style: none;
    margin: 0;
    padding: 10px 0 0 0;
    position: absolute;
    z-index: 2;
    right: 0;
    bottom: 0;
    left: 0;
    height: 30px;
    text-align: center;
    & li {
      position: relative;
      display: inline-block;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: rgba(255, 255, 255, 0.6);
      cursor: pointer;
      margin: 0 3px 5px;
      .transition(~'background-color, color', 100ms);
      & button {
        display: none;
      }
      &[aria-hidden=false] {
        background-color: white;
      }
    }
  }
  &-content {
    position: absolute;
    width: 660px;
    left: 0px;
    top: 127px;
    right: 0px;
    z-index: 10;
    margin-left: auto;
    margin-right: auto;
    border-radius: @border-radius-normal;
    overflow: hidden;
    
    @media @md {
      display: none;
    }

    & .sol-top-search {
      //padding: 30px 35px 30px 15px;
      background-color: @top-content-search-bg;
      @media @sm {
        background-color: @top-content-search-bg-phone;
      }
    }
  }
}
