
.sol-event-search__filters {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  @media @sm {
    margin-bottom: 0;
    flex-direction: column;
  }
  > div {
    flex-basis: 48%;
    @media @md-strict {
      flex-basis: 46%;
    }
    @media @sm {
      flex-basis: 100%;
      width: 100%;
      margin-bottom: 15px;
    }
  }
  .sol-event-search-collapsible, .sol-event-category-collapsible {
    position: relative;
    @media @md {
      width: 100%;
    }
    @media @sm {
      padding-left: 4%;
      padding-right: 4%;
    }
    &__button {
      border-radius: @border-radius-normal;
      webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background: 0;
      border: 0;
      background-color: @button-bg;
      color: @button-text;
      padding: 8px 18px;
      display: flex;
      align-items: center;
      width: 100%;
      height: 50px;
      text-align: left;
      position: relative;
      cursor: pointer;
      line-height: normal;
      overflow: visible;
      box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
      transition-property: background-color;
      transition-duration: 200ms;
      transition-timing-function: ease;
      z-index: 4;
      &__text {
        font-family: @main-font;
        font-size: 20px;
      }
      &__btn {
        display: block;
        width: 40px;
        height: 40px;
        position: absolute;
        right: 4px;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
        &::before, &::after {
          content: '';
          display: block;
          background-color: @white;
          position: absolute;
          top: 50%;
          left: 50%;
          width: 22px;
          height: 4px;
          margin-left: -11px;
          margin-top: -2px;
          border-radius: 2px;
          transition-property: all;
          transition-duration: 200ms;
          transition-timing-function: ease;
        }
        &::after {
          transform: rotate(90deg);
        }
      }
    }
    &__content {
      background-color: @white;
      width: 95%;
      position: absolute;
      top: 40px;
      padding: 0 13px;
      left: 50%;
      -webkit-transform: translatex(-50%);
      -moz-transform: translatex(-50%);
      -ms-transform: translatex(-50%);
      -o-transform: translatex(-50%);
      transform: translatex(-50%);
      max-height: 0;
      overflow: hidden;
      transition-property: padding, max-height;
      transition-duration: 200ms;
      box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.1);
      @media @sm {
        width: 90%;
      }
      .sol-article-filter__date {
        label {
          font-size: 14px;
          font-weight: bold;
          line-height: 1;
          color: @black;
          margin-top: 11px;
        }
        .el-date-editor {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 15px;
          i {
            display: none;
          }
          
          /*input {
            display: flex;
            border: solid 1px #ececec;
            border-radius: 2px;
            min-height: 40px;
            font-size: 15px;
            height: auto;
            padding: 5px 15px;
            max-width: 42%;
           
            &#filterStartDate, #filterEndDate {
              position: relative;
            }
          }*/
          &:first-child {
            flex-basis: 100%;
            max-width: 100%;
          }
        }
        .sol-form-btn {
          &__container {
            flex-basis: 100%;
            width: 100%;
            .sol-btn {
              width: 100%;
            }
          }
        }

      }

      &__dates {
        display: flex;
        justify-content: space-between;
        flex-direction: row !important;
        > div {
          display: inline-flex;
          flex-basis: 47%;
          max-width: 47%;
          flex-direction: column;
        }
      }

    }
    &--expanded {
      .sol-event-search-collapsible__content, .sol-event-category-collapsible__content {
        max-height: 1000px !important;
        z-index: 2;
        overflow: visible;
        padding: 31px 13px 22px;
      }
      .sol-event-search-collapsible__button, .sol-event-category-collapsible__button {
        background-color: @color-2;
        color: @white;
        &__btn {
          &::after {
            transform: rotate(0deg)
          }
        }
      }
    }
  }
}

.sol-event-search__filter {
  @media @sm {
    z-index: 10;
  }
}

.sol-event-search__categories__content {
  > ul {
    padding-left: 12px !important;
  }
  input[type=checkbox],
  & input[type=radio] {
    display: none;
    & + label:before {
      content: "";
      box-sizing: border-box;
      display: inline-block;
      width: 20px;
      height: 20px;
      border: 2px solid @form-input-chk-empty-border;
      border-radius: 3px;
      position: relative;
      top: -1px;
      margin-right: 10px;
      float: left;
      text-align: center;
      .transition(~  'color, background-color, border-color, border-width');
    }

    & + label {
      display: block;
      font-weight: 400;
      margin-bottom: 10px;
      cursor: pointer;
      font-size: 15px;
      line-height: 1.38;
      color: #3b3b3b;
      span {
        font-size: 16px;
        line-height: 1.38;
        color: #969696;
      }
    }
  }

  & input[type=checkbox] {
    &:checked + label:before {
      .icon(@fa-var-check, 12px);
      padding-top: 2px;
      background-color: @form-input-chk-bg;
      border-color: @form-input-chk-border;
      color: @form-input-chk-text;
    }
    & + label {
      &:hover {
        &::before {
          border-color: rgba(110, 205, 233, 0.4);
        }
      }
    }
  }

  & input[type=radio] {
    & + label:before {
      border-radius: 50%;
    }
    &:checked + label:before {
      background-color: #204560;
      border-color: #204560;
    }
  }
}

.sol-event-search__filter-n-categories .reset-btn {
  .no-appearance;
  cursor: pointer;
  font-family: @main-font;
  &:hover span {
    text-decoration: underline;
  }
}

.sol-event-search__filter-n-categories .font-btn {
  @media @sm {
    margin-left: 4%;
    margin-right: 4%;
  }
}

