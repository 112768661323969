.sol-opening-hours {
    text-align: left;
    @media @sm {
        max-width: 100%;
        overflow: auto;
    }
    table {
        border-spacing: 0;
        width:100%;
        margin-bottom: 20px;
    }

    tbody tr:first-child {
        background-color:rgba(42, 128, 156, 0.50) !important;
    }

    tbody tr:nth-child(odd) {
        background-color:rgba(42, 128, 156, 0.15);
    }
    td, th {
        padding:5px;
    }

    thead {
        text-decoration: underline;
    }
}
