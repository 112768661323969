.sol-startpage-entrances .sv-script-portlet,
.sol-landing-page__listing .sv-script-portlet {
  width: 100%;
}



.sol-landing-large {
  display: flex;
  flex-wrap: wrap;
  margin: 20px 0;
  &__item {
    flex: 0 0 23%;
    //margin-right: 20px;
    position: relative;
    padding-left: 33px;
    padding-bottom: 13px;
    padding-bottom: 20px;
    padding-right: 15px;
    margin: 0 1%;
    a, div {
      text-decoration: none;
      i:not(.fa-external-link) {
        background: @sol-entrance-icon-bg;
        width: 22px;
        height: 22px;
        color: @sol-entrance-icon-color;
        border-radius: 50%;
        text-align: center;
        font-weight: 300;
        font-size: 22px;
        margin-right: 5px;
        position: absolute;
        left: 0;
       
        @media @md {
          left: auto;
          top: 4px;
        }
      }
      i.fa-external-link {
        font-size: 14px;
      }

      &:focus {
        outline-color: @black;
      }
    }
    & > div {
      cursor: initial;
    }
    &:hover a .sol-landing-large__header {
      text-decoration: underline;
    }
    @media only screen and (max-width: 1070px) and (min-width: 952px) {
      flex: 31%;
      flex-grow: 0;
      flex-shrink: 1;
      padding-left: 60px;
    }
    @media only screen and (max-width: 952px) {
      flex: 100%;
      flex-grow: 0;
      flex-shrink: 1;
      padding-left: 15px;
      margin-right: 0;
      margin-top: 0;
    }
  }
  &__header {
    display: inline-block;
    font-weight: 400;
    font-size: 20px;
    margin-top: 0;
    margin-bottom: 3px;
    @media only screen and (max-width: 1070px) {
      padding-left: 35px;
    }
  }

  &__text {
 
    @media @md {
      display: none;
    }
  }
}
.sol-startpage-entrances,
.sol-landing-page__listing {
  background: @startpage-entrance;
  margin-top: -6px;

  .sv-text-portlet { //Hej, vad kan vi hjälpa er med?
    @media @md {
      display: block;
    }
    background-color: @color-1;
    width: 100%;
    
    &-content {
      margin-top: 10px;
      margin-bottom: 10px;
      text-align: center;

      h2 {
        padding-top: 10px;
        padding-bottom: 10px;
        font-size: 20px;
        color: @white;
      }
    }
  }

  .sol-landing-large {
    &__item {
      a, a h2, div, div h2 {
        color: @startpage-entrance-color;
      }
      
    }
    &__text {
      color: @startpage-entrance-color;
      font-size: 16px;
      font-weight: 400;
      margin-top: 0;
    }
  }
}
