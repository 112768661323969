footer {
  .sol-bottom {
    background-color: @primary-color;
    background-image: url(/webdav/files/System/resources/graphics/Mora_kurbits-2l.svg), url(/webdav/files/System/resources/graphics/Mora_kurbits-2r.svg);
    background-position: left 50%, right 50%;
    background-repeat: no-repeat;
    background-size: 380px 100%;

    @media @lg {
      background-image: url(/webdav/files/System/resources/graphics/Mora_kurbits-2l.svg);
      background-size: 420px 100%;
    }

    @media @md {
      background-image: none;
    }
  }

  .sol-image-centered img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;

    @media @lg {
      margin-top: 20px;
    }

    @media @xl {
      margin-top: 20px;
    }
  }
}
